<template>
  <div class="sp-main-div">
    <div class="sp-page-header">
      <agc-page-header :title="$t('ctp.taskManage')" :show-back="false" />
    </div>
    <div class="sp-body">
      <div class="sp-main-tabs">
        <el-tabs v-model="tabsetActive" @tab-click="tabChange">
          <el-tab-pane
            v-for="(tab, idx) in list"
            :label="tab.label"
            :name="tab.name"
            :key="'tab' + idx"
          />
        </el-tabs>
      </div>
      <div style="padding:8px 0 28px 0;">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Suppliermain',
  data() {
    return {
      list: [
        { label: this.$t('ctp.taskManage'), name: '/sp/list' }
        // {label:"质量确认管理",name:"/sp/quality"}
      ],
      tabsetActive: '/sp/list'
    };
  },
  mounted() {
    this.$router.push({ path: this.tabsetActive });
  },
  methods: {
    tabChange() {
      this.$router.push({ path: this.tabsetActive });
    },
    openHelpDocument() {
      const href =
        `https://developer.huawei.com/consumer/cn/doc/development/${
          +'AppGallery-connect-Guides/agc-translation-introduction-0000001056349323'}`;
      window.open(href, '_blank');
    }
  }
};
</script>

<style scoped>
.sp-main-tabs /deep/ .el-tabs__item.is-active {
  border-bottom: 4px solid #007dff;
}
.sp-page-header /deep/ .agc-page-header__title {
  padding: 20px 0;
  font-size: 32px;
  color: #191919;
  line-height: 42px;
  font-weight: 700;
}

.sp-body {
  background: #fff;
  min-height: 520px;
}
</style>
